<section [ngClass]="{'pt-1':!isShowAll}" [ngStyle]="{'padding-top':isShowAll?'7rem':null}">
    <div class="container post pt-1">
        <div class="row DCB-card" style="padding-top: 1.25rem!important;">


            <div class="accordion" id="accordionExample">
                <div class="accordion-item">
                    <h2 class="accordion-header" id="headingOne">
                        <button class="accordion-button" type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                            Undergraduate Programmes
                        </button>
                    </h2>
                    <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne"
                        data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                            <div class="row">
                                <div class="col-xl-4 col-md-6 mb-5 pt-2" *ngFor="let course of undergraduateCourses">
                                    <div class="card shadow-sm h-100">
                                        <div style="position:relative;">
                                            <img [src]="course.COURSE_IMAGE ? url+course.COURSE_IMAGE : '../../../assets/img/subject-img.jpg'"
                                                class="card-img-top" alt="..." width="354" height="185">
                                            <span
                                                class="badge rounded-pill bg-light text-dark user-pill px-3 py-2"></span>
                                        </div>
                                        <div class="card-body">
                                            <h5 class="card-title mt-2">{{course.COURSE_NAME}}</h5>
                                            <p class="card-text">{{course?.COURSE_DESCRIPTION?.substr(0,150)}}
                                                <a *ngIf="course.COURSE_DESCRIPTION?.length > 75"
                                                    (click)="gotoCourseDetail(course)" href="javascript:return(0);">View
                                                    More</a>
                                            </p>
                                            <div class="text-center">
                                                <div><span><button class="btn btn-sm btn-seeall-1"
                                                            (click)="gotoCourseDetail(course)">View
                                                            Programme</button></span></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="accordion-item">
                    <h2 class="accordion-header" id="headingTwo">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                            Postgraduate Programmes
                        </button>
                    </h2>
                    <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
                        data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                            <div class="row">


                                <div class="col-xl-4 col-md-6 mb-5 pt-2" *ngFor="let course of postgraduateCourses">
                                    <div class="card shadow-sm h-100">
                                        <div style="position:relative;">
                                            <img [src]="course.COURSE_IMAGE ? url+course.COURSE_IMAGE : '../../../assets/img/subject-img.jpg'"
                                                class="card-img-top" alt="..." width="354" height="185">
                                            <span
                                                class="badge rounded-pill bg-light text-dark user-pill px-3 py-2"></span>
                                        </div>
                                        <div class="card-body">
                                            <h5 class="card-title mt-2">{{course.COURSE_NAME}}</h5>
                                            <p class="card-text">{{course?.COURSE_DESCRIPTION?.substr(0,150)}}
                                                <a *ngIf="course.COURSE_DESCRIPTION?.length > 75"
                                                    (click)="gotoCourseDetail(course)" href="javascript:return(0);">View
                                                    More</a>
                                            </p>
                                            <div class="text-center">
                                                <div><span><button class="btn btn-sm btn-seeall-1"
                                                            (click)="gotoCourseDetail(course)">View
                                                            Programme</button></span></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="text-center mb-3">
            <button type="button" class="btn btn-blue btn-sm">Small button</button>
        </div> -->
    </div>
</section>