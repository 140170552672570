import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { ICourse } from '../../components/Shared/models/course';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-all-courses',
  templateUrl: './all-courses.component.html',
  styleUrls: ['./all-courses.component.css']
})
export class AllCoursesComponent implements OnInit {

  @Input() courseList: Array<ICourse> = [];
  undergraduateCourses: Array<ICourse> = [];
  postgraduateCourses: Array<ICourse> = [];
  @Input() isShowAll: boolean = false;
  url: string = environment.videoUrl;
  constructor(private route: Router) { }

  ngOnInit(): void {
    // console.log('isShowAll:=', this.isShowAll);
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes.courseList) {
      this.filterCourses();
    }
  }

  filterCourses() {
    this.undergraduateCourses = this.courseList.filter(course => course.COURSE_GRADUATION === 1);
    this.postgraduateCourses = this.courseList.filter(course => course.COURSE_GRADUATION === 2);
  }

  //[routerLink] ="['/view-course-details']"
  gotoCourseDetail(course: ICourse) {
    if (sessionStorage.UserId) {
      this.route.navigate(['view-course-details'], { state: course });
      // (localStorage.setItem('CourseFee', course.COURSE_FEES) as any);
      (localStorage.setItem('cID', course.COURSE_ID) as any);
      (localStorage.setItem('cName', course.COURSE_NAME));
      (localStorage.setItem('appTyp', course.COURSE_GRADUATION))
    }
    else {
      this.route.navigate(['eRP/view-course-details'], { state: course });
    }

  }
}
